import http from 'config/http';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import FallbackLoader from 'modules/shared/components/FallbackLoader/FallbackLoader';
import { authActions } from 'store/auth';
import { billingActions } from 'store/billing';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import styles from './MainLayout.module.scss';
import { useNavigate } from 'react-router-dom';

const MainLayout = () => {
    const navigate = useNavigate();

    const { token, user } = useSelector((state) => state.auth);
    const { amount } = useSelector((state) => state.billing);

    const query = useQuery(
        ['initialData'],
        async () => {
            const response = await Promise.all([
                http.get(`${process.env.REACT_APP_API_URL}/profile`),
                http.get(`${process.env.REACT_APP_TQ_API_URL}/bills/amount`),
            ]);

            return [response[0].data, response[1].data];
        },
        {
            refetchInterval: 1000 * 60 * 60 * 12, // in milliseconds (12 hours)
            enabled: Boolean(token),
        }
    );

    const dispatch = useDispatch();

    const profile = query.data ? query.data[0] : null;
    const billAmount = query.data ? query.data[1].amount : null;

    useEffect(() => {
        if (!token) {
            navigate('/login', { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set profile
    useEffect(() => {
        if (profile) {
            dispatch(authActions.setUser(profile));
            dispatch(billingActions.setCurrency(profile.client.billing_details.currency));
        }
    }, [profile, token, dispatch]);

    // Set bill amount
    useEffect(() => {
        if (billAmount !== undefined) {
            dispatch(billingActions.setAmount(billAmount));
        }
    }, [billAmount, dispatch]);

    if (query.isLoading || !user || amount === undefined) return <FallbackLoader />;

    return (
        <div className={styles.mainWrapper}>
            <Sidebar />

            <div className={styles.contentWrapper}>
                <Outlet />

                <div className="mt-auto">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default MainLayout;
